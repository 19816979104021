const routes = [
  {
    path: '/race/upload',
    name: 'RaceUpload',
    component: () => import('../views/Race/Upload.vue'),
    meta: { auth: 'admin' },
  },
  {
    path: '/race/:raceId',
    name: 'Race',
    component: () => import('../views/Race/Display.vue'),
  },
  {
    path: '/race/pending/all',
    name: 'RacePendingAll',
    component: () => import('../views/Race/PendingAll.vue'),
  },
  {
    path: '/race/pending/edit/:id',
    name: 'RacePendingEdit',
    component: () => import('../views/Race/PendingEdit.vue'),
  },
];

export default routes;
