<template>
  <div id="app">
    <div v-if="!hideNavbar()">
      <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand to="/home">{{$t('home')}}</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item-dropdown>
              <template slot="button-content">Classements</template>
              <b-dropdown-item to="/ranking">
                Classement national
              </b-dropdown-item>
              <b-dropdown-item to="/rankingCovid2021">
                Classement national modifié (Covid-19)
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <!-- <b-navbar-nav>
            <b-nav-item to="/ranking">{{$t('nationalRanking')}}</b-nav-item>
          </b-navbar-nav> -->
          <b-navbar-nav>
            <b-nav-item to="/results">{{$t('results')}}</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
             <!-- <b-nav-item-dropdown right>
              <template slot="button-content">
                <b-icon-globe/>
                {{$t('languages.'+$i18n.locale)}}
              </template>
              <b-dropdown-item
                v-for="lng in $i18n.availableLocales"
                :key="lng"
                @click="changeLanguage(lng)"
              >
                {{$t('languages.'+lng)}}
              </b-dropdown-item>
            </b-nav-item-dropdown> -->
            <b-nav-item-dropdown v-if="$auth.check()" right>
              <template slot="button-content"><em>{{userName}}</em></template>
              <b-dropdown-item v-if="$auth.check('admin')" to="/admin">
                {{$t('admin')}}
              </b-dropdown-item>
              <b-dropdown-item v-if="$auth.check('admin')" to="/race/upload">
                Envoi course
              </b-dropdown-item>
              <b-dropdown-item v-if="$auth.check('admin')" to="/race/pending/all">
                Courses à valider
              </b-dropdown-item>
              <b-dropdown-item @click="logout">{{$t('logout')}}</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item v-if="!$auth.check()" to="/login">{{$t('login')}}</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <b-container fluid>
      <router-view/>
    </b-container>
  </div>
</template>
<script>

export default {
  computed: {
    userName() {
      const user = this.$auth.user();
      if (user) {
        return user.username;
      }
      return '';
    },
  },
  methods: {
    logout() {
      this.$auth
        .logout({
          makeRequest: false,
          redirect: { name: 'Home' },
        });
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    hideNavbar() {
      return this.$route.meta.hideNavBar || false;
    },
  },
};
</script>

<style>
  body {
    font-family:Arial, Helvetica, sans-serif;
  }
  .red {
    color: red;
  }
  .green {
    color: green;
  }
  .no-padding td{
    padding-top: 0px;
    padding-bottom: 0px;
  }
</style>
